
import React, {useState, useEffect } from 'react';
import { ThemeProvider, createTheme, Arwes, Button, SoundsProvider, createSounds, Words, Loading  } from 'arwes';

import { useHistory } from "react-router-dom";

import './style/style.css'

const myTheme = {
  color: {
    primary: {
      base: '#be26fc',
      dark: '#8e1bbd',
      light: '#c95bf6'
    },
    header: {
      base: '#fc26fa',
      dark: '#a818a7',
      light: '#f458f2'
    }
  }
};

const mySounds = {
  shared: { volume: 1, },  // Shared sound settings
  players: {  // The player settings
    click: {  // With the name the player is created
      sound: { src: ['/static/sound/click.mp3'] }  // The settings to pass to Howler
    },
    typing: {
      sound: { src: ['/static/sound/typing.mp3'] },
      settings: { oneAtATime: true }  // The custom app settings
    },
    deploy: {
      sound: { src: ['/static/sound/deploy.mp3'] },
      settings: { oneAtATime: true }
    },
  }
};

const App = ()=> {

  document.title ='新昉科技'

  let history = useHistory();

  const LinkTo = (link)=>{
    history.push(link)
  }

  return (
    <ThemeProvider theme={createTheme({myTheme})}>
      <SoundsProvider sounds={createSounds(mySounds)}> 
        <Arwes animate background='/static/img/background-large.jpg' pattern='/static/img/glow.png'>
            <div className="container main"> 
         
                  <div className="center">
                      <h3><Words  animate layer='secondary'>新昉科技</Words></h3>
                      <Words animate layer='success'>助力小微企业信息化</Words>
                    <div style={{paddingTop:'20px'}}>
                      <Button animate className="button" onClick={()=>LinkTo('/about')}>About</Button>
                      <Button animate className="button" onClick={()=>LinkTo('/service')}>Service</Button>
                      <Button animate className="button" onClick={()=>LinkTo('/product')}>Product</Button>
                      <Button animate className="button" onClick={()=>LinkTo('/contact')}>Contact</Button>
                    </div>
                  </div>
                  <div className="text-center">
                      <a href="https://beian.miit.gov.cn/" className="home"><Words animate>粤ICP备20055302号</Words></a>
                  </div>
             
            </div>
        </Arwes>
    </SoundsProvider>
  </ThemeProvider>
  )
}

export default App;
