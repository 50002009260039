import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";

import About from './pages/about'
import Service from './pages/service'
import Product from './pages/product'
import Contact from './pages/contact'

const IRouter = ()=>{
  return (
    <Router>
        <Switch>
          <Route path="/" exact={true}  component={App} />
          <Route path="/about"  component={About} /> 
          <Route path="/service"  component={Service} /> 
          <Route path="/product"  component={Product} /> 
          <Route path="/contact"  component={Contact} /> 
        </Switch>
    </Router>
  );
}

ReactDOM.render(
 
    <IRouter />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
