import Project from 'arwes/lib/Project'
import React from 'react'
import Layouts from '../layouts'
import {  Words, List  } from 'arwes';

const Product = (props)=>{

    document.title = '产品-新昉科技'

    return (
        <Layouts>
            <Project
                animate
                header='我们的产品'
            >
                {anim => (
                    <div> 
                        <Words animate  > PRODUCTS </Words>
                        <List node='ul' style={{paddingTop:'40px'}}>
                            <li>国际货代软件-ELS</li>
                            <li>空运Cargo平台-Airwaybill.cn</li>
                            <li>客户关系管理系统-ECRM</li>
                            <li>服务监控平台-MonitorX</li>
                        </List>
                    </div>
                    
                )}
                 
            </Project>
        </Layouts>
    )
}

export default Product