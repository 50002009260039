import Project from 'arwes/lib/Project'
import React from 'react'
import Layouts from '../layouts'
import {  Words, List  } from 'arwes';

const Service = (props)=>{

    document.title = '我们的服务-新昉科技'

    return (
        <Layouts>
            <Project
                animate
                header='我们的服务'
            >
                {anim => (
                    <div> 
                        <Words animate  >SERVICE </Words>
                        <List node='ol' style={{paddingTop:'40px'}}>
                            <li>企业系统订制服务</li>
                            <li>系统售后运维服务</li>
                            <li>官网设计与开发</li>
                            <li>产品订制</li>
                            <li>APP与小程序开发</li>
                        </List>
                    </div>
                    
                )}
                 
            </Project>
        </Layouts>
    )
}

export default Service