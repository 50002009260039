import Project from 'arwes/lib/Project'
import React from 'react'
import Layouts from '../layouts'
import {  Words, List  } from 'arwes';

const About = (props)=>{

    document.title = '关于我们-新昉科技'

    return (
        <Layouts>
            <Project
                animate
                header='About us'
            >
                {anim => (
                    <div> 
                        <Words animate  >新昉科技是一家集软件开发、系统运维、移动开发与一体的互联网软件企业 </Words>
                        <List node='ul' style={{paddingTop:'40px'}}>
                            <li>新昉科技拥有一支高水平的研发团队和运维团队，参与过国内阿里、腾讯、金山等多个核心业务的开发与运维，技术经验丰富</li>
                            <li>多年来，服务于小企业、电商、国际货代、房地产等行业，为企业内部信息系统提供定制化方案，解决企业信息化、现代化问题。</li>
                            <li>如果您的企业正试图为传统系统寻找一条出路，我们的团队可以助您一臂之力。</li>
                        </List>
                    </div>
                    
                )}
                 
            </Project>
        </Layouts>
    )
}

export default About