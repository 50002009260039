import Project from 'arwes/lib/Project'
import React from 'react'
import Layouts from '../layouts'
import {  Words, List  } from 'arwes';

const Contact = (props)=>{

    document.title = '联系我们-新昉科技'

    return (
        <Layouts>
            <Project
                animate
                header='联系我们'
            >
                {anim => (
                    <div> 
                        <Words animate  > Contact </Words>
                        <div style={{paddingTop:'20px'}}>
                            <p>地址:深圳市龙岗区龙岗街道南联龙溪小区东二区五巷三号201</p>
                            <p>EMAIL:admin@ibayeux.com</p>
                        </div>
                    </div>
                    
                )}
                 
            </Project>
        </Layouts>
    )
}

export default Contact