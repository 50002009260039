
import React from 'react';
import { ThemeProvider, createTheme, Arwes,   SoundsProvider, createSounds,Header,Words } from 'arwes';

import {Link} from 'react-router-dom'

const myTheme = {
  color: {
    primary: {
      base: '#be26fc',
      dark: '#8e1bbd',
      light: '#c95bf6'
    },
    header: {
      base: '#fc26fa',
      dark: '#a818a7',
      light: '#f458f2'
    }
  }
};

const mySounds = {
  shared: { volume: 1, },  // Shared sound settings
  players: {  // The player settings
    click: {  // With the name the player is created
      sound: { src: ['/static/sound/click.mp3'] }  // The settings to pass to Howler
    },
    typing: {
      sound: { src: ['/static/sound/typing.mp3'] },
      settings: { oneAtATime: true }  // The custom app settings
    },
    deploy: {
      sound: { src: ['/static/sound/deploy.mp3'] },
      settings: { oneAtATime: true }
    },
  }
};

const Layouts = (props)=> {
  return (
    <ThemeProvider theme={createTheme({myTheme})}>
      <SoundsProvider sounds={createSounds(mySounds)}> 
        <Arwes animate background='/static/img/background-large.jpg' pattern='/static/img/glow.png'>
            <div>
                <Header animate>
                    <div className="container">
                        <h1 style={{ margin: 0 }}><Link to="/" className="home"><Words animate >Home</Words></Link></h1>
                    </div> 
                </Header>
            </div>
            <div className="container" style={{ paddingTop:'40px' }}> 
         
                   {props.children}
             
            </div>
        </Arwes>
    </SoundsProvider>
  </ThemeProvider>
  )
}

export default Layouts;
